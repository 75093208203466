import { withStyles } from '@material-ui/core/styles';
import { TextField, InputBase } from '@material-ui/core';

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#FF8936',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FF8936',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FF8936',
      },
    },
  },
})(TextField);

export const CssInput = withStyles({
  input: {
    borderBottom: '1px solid #ced4da',
    '&:focus': {
      borderColor: '#FF8936',
    },
  },
})(InputBase);

export const CssOutlineInput = withStyles({
  input: {
    borderRadius: 4,
    border: '1px solid #ced4da',
    padding: '18px 26px 18px 12px',
    '&:focus': {
      borderColor: '#FF8936',
      borderRadius: 4,
    },
  },
})(InputBase);

export const CssOutlineInputCP = withStyles({
  input: {
    borderRadius: 4,
    border: '1px solid #ced4da',
    padding: '12px',
    '&:focus': {
      borderColor: '#FF8936',
      borderRadius: 4,
    },
  },
})(InputBase);

export const LoginTextField = withStyles({
  root: {
    borderBottom: '1px solid #E6E6E6',
    '&:focus': {
      borderColor: '#FF8936',
    },
  },
})(InputBase);

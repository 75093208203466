import { createReducer } from 'typesafe-actions';
import { ProductState } from './interface';
import { ProductAction } from './types';
import {
  SET_CONNECT_COURSE,
  CONNECT_COURSE,
  DISCONNECT_COURSE,
  SET_CONNECT_OPTION,
  CONNECT_OPTION,
  DISCONNECT_OPTION,
} from './actions';

const intialState: ProductState = {
  courses: [],
  options: [],
};

const reducer = createReducer<ProductState, ProductAction>(intialState, {
  [SET_CONNECT_COURSE]: (state, { payload: { courses } }) => {
    return {
      ...state,
      courses,
    };
  },
  [CONNECT_COURSE]: (state, { payload: { courses } }) => {
    return {
      ...state,
      courses: [...state.courses, ...courses],
    };
  },
  [DISCONNECT_COURSE]: (state, { payload: { ids } }) => {
    return {
      ...state,
      courses: state.courses.filter((item) => ids.indexOf(item.id) === -1),
    };
  },
  [SET_CONNECT_OPTION]: (state, { payload: { options } }) => {
    return {
      ...state,
      options,
    };
  },
  [CONNECT_OPTION]: (state, { payload: { options } }) => {
    return {
      ...state,
      options: [...state.options, ...options],
    };
  },
  [DISCONNECT_OPTION]: (state, { payload: { ids } }) => {
    return {
      ...state,
      options: state.options.filter((item) => ids.indexOf(item.id) === -1),
    };
  },
});

export default reducer;

import { createAction } from 'typesafe-actions';
import { UserState, PermissionState } from './interface';

//로그인
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_RETURN_URL = 'auth/LOGIN_RETURN_URL';

//회원가입
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_USERNAME = 'auth/REGISTER_USERNAME';

//아이디찾기
export const FIND_ACCOUNTID = 'auth/FIND_ACCOUNTID';
//비밀번호변경
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';

//로그아웃
export const LOGOUT = 'auth/LOGOUT';

//관리자권한체크
export const ADMIN_ACCESS = 'auth/ADMIN_ACCESS';

//유저정보
export const GET_USER_INFO = 'auth/GET_USER_INFO';

export const loginSuccess = createAction(LOGIN_SUCCESS)<string>();
export const loginReturnUrl = createAction(LOGIN_RETURN_URL)<string>();

export const registerSuccess = createAction(REGISTER_SUCCESS)<string>();
export const registerUsername = createAction(REGISTER_USERNAME)<string>();

export const findAccountId = createAction(FIND_ACCOUNTID)<string | null>();
export const resetPassword = createAction(RESET_PASSWORD)<{
  resetHash: string | null;
  expireTime: Date | null;
}>();

export const logout = createAction(LOGOUT)();

export const adminAccess = createAction(ADMIN_ACCESS)<PermissionState>();

export const getUserInfo = createAction(GET_USER_INFO)<UserState>();

import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NavbarItem from '../../common/navbar/navbarItem';
import { OnlyRootAdminComponent } from '../../common/AdminRestrictComponent';

const UserNavbar = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="회원 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavbarItem url="/admin/user" title="회원 관리" />
          <OnlyRootAdminComponent
            component={
              <>
                <NavbarItem url="/admin/manager" title="관리자 관리" />
                <NavbarItem url="/admin/role" title="등급 관리" />
                <NavbarItem url="/admin/memo" title="메모 관리" />
                <NavbarItem url="/admin/cplist" title="CP 관리" />
              </>
            }
          />
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default UserNavbar;

import React, { useState } from 'react';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import NavbarItem from '../../common/navbar/navbarItem';

const ProductNavbar = () => {
  const [open, setOpen] = useState(false);

  const handleContentClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <CardGiftcardIcon />
        </ListItemIcon>
        <ListItemText primary="상품 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <NavbarItem title="배송옵션 관리" url="/admin/option" />
          <NavbarItem title="과정 관리" url="/admin/course" />
          <NavbarItem title="카테고리 관리" url="/admin/product/category" />
          <NavbarItem title="상품 관리" url="/admin/product" />
          <NavbarItem title="상품 특징 관리" url="/admin/product/charForm" />
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default ProductNavbar;

import { createAction } from 'typesafe-actions';

import { IRandomImage } from './interface';

export const SET_RANDOM_IMAGE = 'randomImage/SET_RANDOM_IMAGE';
export const setRandomImage = createAction(SET_RANDOM_IMAGE)<{
  images: IRandomImage[];
}>();

export const ADD_RANDOM_IMAGE = 'randomImage/ADD_RANDOM_IMAGE';
export const addRandomImage = createAction(ADD_RANDOM_IMAGE)<{
  images: IRandomImage[];
}>();

export const REMOVE_RANDOM_IMAGE = 'randomImage/REMOVE_RANDOM_IMAGE';
export const removeRandomImage = createAction(REMOVE_RANDOM_IMAGE)<{
  ids: number[];
}>();

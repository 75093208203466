import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TocIcon from '@material-ui/icons/Toc';
import NavbarItem from '../../common/navbar/navbarItem';

const LogNavbar = () => {
  const [open, setOpen] = useState(false);

  const handleContentClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <TocIcon />
        </ListItemIcon>
        <ListItemText primary="분석" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <NavbarItem url="/admin/analysis/pagelog" title="접속로그" />
          <NavbarItem url="/admin/analysis/funnel" title="최초 접속기록" />
          <NavbarItem url="/admin/analysis/selling" title="최근 판매 수량" />
          <NavbarItem
            url="/admin/analysis/crawling"
            title="네이버 블로그 크롤링"
          />
          <NavbarItem url="/admin/analysis/cart" title="장바구니 내역" />
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default LogNavbar;

import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import useCreatePageLog from '../../../hooks/common/useCreatePageLog';
import useReturnUrl from '../../../hooks/auth/useReturnUrl';

const referrer = document.referrer; //페이지 최초 진입주소
declare global {
  interface PageViewParameter {
    page_location: string;
    page_path: string;
  }

  interface PurchaseItem {
    id: string;
    name: string;
    quantity: number;
    price: number;
  }

  interface PurchaseParameter {
    transaction_id: string;
    value: number;
    currency: string;
    items: PurchaseItem[];
  }

  interface Window {
    gtag: (
      action: string,
      gaId: string,
      parameter: PageViewParameter | PurchaseParameter,
    ) => void;
    naverRequest: (reward?: number) => void;
    hotjar: () => void;
  }
}

const gaId = 'G-0LC6LCGKVD';

const LogCheck = ({ location }: RouteComponentProps) => {
  const updateReturnUrl = useReturnUrl(); //로그인후 돌아갈 페이지
  const createLogRequest = useCreatePageLog();

  //이전 페이지주소
  const [prevUrl, setPrevUrl] = useState({
    pathname: '',
    search: '',
  });

  useEffect(() => {
    //현재 페이지url
    const nowUrl = {
      pathname: location.pathname,
      search: location.search,
    };

    if (prevUrl.pathname + prevUrl.search !== nowUrl.pathname + nowUrl.search) {
      setPrevUrl(nowUrl);

      if (window.location.hostname !== 'localhost') {
        //구글애널리틱스, 네이버로그분석 로그수집실행
        if (!nowUrl.pathname.includes('admin')) {
          if (typeof window.gtag === 'function') {
            window.gtag('config', gaId, {
              page_location: window.location.href,
              page_path: location.pathname,
            });
          }
          if (
            !nowUrl.pathname.includes('/order/output') &&
            typeof window.naverRequest === 'function'
          )
            window.naverRequest();
          // if (typeof window.hotjar === 'function')
          //   window.hotjar();
        }
        //로그 기록
        createLogRequest({
          variables: {
            current: nowUrl.pathname + nowUrl.search,
            referer: prevUrl.pathname + prevUrl.search,
            funnel: referrer,
          },
        });
      }

      //로그인 후 돌아갈 주소
      if (nowUrl.pathname === '/login') {
        if (
          prevUrl.pathname === '/login' ||
          prevUrl.pathname === '/mypage/info' ||
          prevUrl.pathname === '/register/form' ||
          prevUrl.pathname === '/idpwsearch/reset' ||
          prevUrl.pathname === '/idpwsearch/idresult'
        )
          updateReturnUrl('/');
        else updateReturnUrl(prevUrl.pathname + prevUrl.search);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <></>;
};

export default withRouter(LogCheck);

// PC/모바일 헤더,메뉴
import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
//style, icon
import useStyles from '../../hooks/header/useStyles';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
//component
import MobileCategoryList from './mobile/MobileCategoryList';
import MobileBottomMenu from './mobile/MobileBottonMenu';
import PCBottomMenu from './pc/PCBottomMenu';
import PCCategoryList from './pc/PCCategoryList';
import PCTopMenu from './pc/PCTopMenu';
import HeaderSearchPopper from './HeaderSearchPopper';
//logo image
import DokhakRogo from '../../img/logo/logo.png';

const HeaderAppBar = ({ history, location }: RouteComponentProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false); // 모바일버전 오른쪽 햄버거메뉴

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  //모바일 햄버거아이콘클릭
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  //상단 뒤로가기 아이콘 버튼
  const goBack = () => {
    history.go(-1);
  };

  //주소 이동되면(링크있는 메뉴 클릭하면) 모바일버전 메뉴 닫기
  useEffect(() => {
    if (open) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={classes.appbar}
        elevation={0}
        color="inherit"
      >
        {/* PC 윗 줄 */}
        <Hidden smDown>
          <Toolbar className={classes.toolbar}>
            <Link to="/" className={classes.link}>
              <img src={DokhakRogo} className={classes.title} alt="로고" />
            </Link>
            {/* PC - 상단 메뉴 */}
            <PCTopMenu />
          </Toolbar>
        </Hidden>
        <div className={classes.appbar} />
        <Toolbar variant="dense" className={classes.toolbar}>
          {/* 모바일 - 뒤로가기 버튼 */}
          <Hidden mdUp>
            <IconButton edge="start" onClick={goBack}>
              <ChevronLeftIcon />
            </IconButton>
          </Hidden>
          {/* PC - 로고, 카테고리, 수강후기 */}
          <Hidden smDown>
            <PCCategoryList />
            <div className={classes.grow} />
          </Hidden>
          {/* 모바일 - 로고 */}
          <Hidden mdUp>
            <Link to="/" className={classes.link}>
              <img
                src={DokhakRogo}
                className={classes.mobiletitle}
                alt="로고"
              />
            </Link>
          </Hidden>
          {/* 검색아이콘 */}
          <HeaderSearchPopper />
          <Hidden smDown>
            <PCBottomMenu />
          </Hidden>
          {/* 모바일 - 햄버거 메뉴아이콘 */}
          <Hidden mdUp>
            <IconButton edge="start" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
        {/* 모바일 - 카테고리, 내보관함 링크 */}
        <Hidden smUp>
          <MobileBottomMenu />
        </Hidden>
      </AppBar>
      {/* 모바일 - 햄버거 메뉴 */}
      <Hidden mdUp>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <div className={classes.mobiledrawerContainer}>
            <MobileCategoryList />
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default withRouter(HeaderAppBar);

// 패밀리사이트 링크
import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Site } from './interface';
import { useStylesFamilySite } from './FamilySiteList';

interface FamilySiteListItemProps {
  siteList: Site[];
  text: string;
}

const FamilySiteListItem = ({ siteList, text }: FamilySiteListItemProps) => {
  const classes = useStylesFamilySite();

  const handleLink = (url: string) => {
    window.open(`${url}`);
  };

  return (
    <>
      <Grid item xs={12} className={classes.titlebox}>
        <div className={classes.titlebox}>
          <Divider orientation="vertical" className={classes.titledivider} />
          <span className={classes.title}>{text}</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {siteList.map((site) => (
            <Grid
              item
              xs={6}
              sm={3}
              md={4}
              key={site.id}
              onClick={() => handleLink(site.url)}
            >
              <Grid container className={classes.linkbox}>
                <Grid item xs={11}>
                  <Typography color="textPrimary" className={classes.sitename}>
                    {site.sitename}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    className={classes.subtext}
                    noWrap
                  >
                    {site.subtext}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <ArrowForwardIosIcon className={classes.arrowicon} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FamilySiteListItem;

import React, { ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import useLogout from '../../hooks/common/useLogout';

import {
  Drawer,
  CssBaseline,
  AppBar,
  IconButton,
  Toolbar,
  Divider,
  List,
  Grid,
} from '@material-ui/core';
import { useStylesNavbar } from '../../hooks/admin/navbar/useStyle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import SelfLogo from '../../img/logo/logo.png';

import TopNavbar from './navbar/top';
import RootSideBar from './navbar/side/RootNavbar';

import useStatus from '../../hooks/auth/useStatus';
import useSetUserPermission from '../../hooks/admin/common/useSetUserPermission';

import RouterLink from './common/Links';

interface Props extends RouteComponentProps {
  children?: ReactNode;
}

const AdminTemplate = ({ children, history }: Props) => {
  const classes = useStylesNavbar();
  const logout = useLogout(history);
  const { isAdmin, permission } = useStatus();
  const { loading, error, onPermissionRequest } = useSetUserPermission();

  const [open, setOpen] = useState(true);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isAdmin) {
      onPermissionRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onPermissionRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('token')]);

  useEffect(() => {
    if (error !== null) {
      alert('접속 권한이 없습니다. 관리자로 로그인해주세요.');
      logout('admin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={`${classes.appBar} ${open ? classes.appBarShift : ''}`}
      >
        <Toolbar>
          {!open && (
            <IconButton
              onClick={onOpen}
              color="inherit"
              aria-label="open drawer"
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          <TopNavbar />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          container
          className={`${classes.drawerHeader} ${classes.toolbar}`}
          alignItems="center"
        >
          <Grid item xs>
            <RouterLink to="/">
              <IconButton>
                <img src={SelfLogo} alt="logo" className={classes.logo} />
              </IconButton>
            </RouterLink>
          </Grid>
          <Grid item xs>
            <Grid container justify="flex-end">
              <IconButton onClick={onClose}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <List className={classes.side}>
          <RootSideBar />
        </List>
      </Drawer>
      <main
        className={`${classes.content} ${open ? classes.contentShift : ''}`}
      >
        {loading || permission.name === null ? '로딩중...' : children}
      </main>
    </div>
  );
};

export default withRouter(AdminTemplate);

import React, { useState, useEffect } from 'react';

import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import NavbarItem from '../../common/navbar/navbarItem';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DvrIcon from '@material-ui/icons/Dvr';

// import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';

import { useSelectBoard } from '../../../../hooks/admin/navbar/useBoard';

import { useLazyQuery } from '@apollo/react-hooks';
import LIST_QUERY from '../../../../hooks/admin/navbar/useBoardCategoryList';

const Navbar = () => {
  const selectBoard = useSelectBoard();

  const [postList, setPostList] = useState<Array<any>>([]);
  const [faqList, setFaqList] = useState<Array<any>>([]);
  const [inquiryList, setInquiryList] = useState<Array<any>>([]);
  const [newInquiry, setNewInquiry] = useState<any>({});

  const [inquiryOpen, setInquiryOpen] = useState(false);
  const [faqOpen, setFaqOpen] = useState(false);
  const [postOpen, setPostOpen] = useState(false);

  const [getCategoryList] = useLazyQuery(LIST_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      setPostList([...result.board]);
      setFaqList([...result.FAQCategory]);
      setInquiryList([...result.inquiryCategory]);
      setNewInquiry({
        ...result.inquiries.items.reduce((data: any, item: any) => {
          data[item.categoryId] = ++data[item.categoryId] || 1;
          return data;
        }, {}),
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onInquiryClick = () => {
    getCategoryList();
    setInquiryOpen(!inquiryOpen);
  };

  const onFaqClick = () => {
    getCategoryList();
    setFaqOpen(!faqOpen);
  };

  const onPostClick = () => {
    getCategoryList();
    setPostOpen(!postOpen);
  };

  const onSelectBoard = (id: number, name: string) => {
    selectBoard(id, name);
  };

  useEffect(() => {
    getCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ListItem button onClick={onInquiryClick}>
        <ListItemIcon>
          <ContactSupportOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary="1:1문의 관리"
          secondary={
            Object.keys(newInquiry).length !== 0 && (
              <Typography
                style={{ color: '#FF9E36', fontWeight: 'bold' }}
                variant="body2"
              >
                New!
              </Typography>
            )
          }
        />
        {inquiryOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={inquiryOpen} timeout="auto" unmountOnExit>
        <NavbarItem
          url="/admin/inquiry/unanswered"
          listItemTextProps={{
            primary: '미답변',
            secondary: Object.keys(newInquiry).length !== 0 && (
              <Typography
                style={{ color: '#FF9E36', fontWeight: 'bold' }}
                variant="body2"
              >
                {
                  Object.values(newInquiry).reduce(
                    (value: any, item: any) => (value += item),
                  ) as number
                }
              </Typography>
            ),
          }}
        />
        <NavbarItem
          url="/admin/inquiry/all"
          listItemTextProps={{
            primary: '모든 문의 보기',
            secondary: Object.keys(newInquiry).length !== 0 && (
              <Typography
                style={{ color: '#FF9E36', fontWeight: 'bold' }}
                variant="body2"
              >
                {
                  Object.values(newInquiry).reduce(
                    (value: any, item: any) => (value += item),
                  ) as number
                }
              </Typography>
            ),
          }}
        />
        {inquiryList.map((item) => (
          <NavbarItem
            url={`/admin/inquiry/${item.id}`}
            key={item.id}
            listItemProps={{
              onClick: () => onSelectBoard(item.id, item.name),
              button: true,
            }}
            listItemTextProps={{
              primary: `${item.name}`,
              secondary: Object.keys(newInquiry).indexOf(String(item.id)) >=
                0 && (
                <Typography
                  style={{ color: '#FF9E36', fontWeight: 'bold' }}
                  variant="body2"
                >
                  {newInquiry[Number(item.id)]}
                </Typography>
              ),
            }}
          />
        ))}
        <NavbarItem url="/admin/inquiry/form" title="자동완성 관리" />
        <NavbarItem url="/admin/inquiry/category" title="1:1문의 카테고리" />
      </Collapse>

      <Divider />

      <ListItem button onClick={onFaqClick}>
        <ListItemIcon>
          <QuestionAnswerOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="FAQ 관리" />
        {faqOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={faqOpen} timeout="auto" unmountOnExit>
        {faqList.map((item: any) => (
          <NavbarItem
            url={`/admin/faq/${item.id}`}
            key={item.id}
            listItemProps={{
              onClick: () => onSelectBoard(item.id, item.name),
              button: true,
            }}
            listItemTextProps={{
              primary: `${item.name}`,
            }}
          />
        ))}
        <NavbarItem url="/admin/faq/category" title="FAQ 카테고리" />
      </Collapse>

      <Divider />

      <ListItem button onClick={onPostClick}>
        <ListItemIcon>
          <DvrIcon />
        </ListItemIcon>
        <ListItemText primary="게시판 관리" />
        {postOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={postOpen} timeout="auto" unmountOnExit>
        {postList.map((item) => (
          <NavbarItem
            url={`/admin/post/${item.id}`}
            key={item.id}
            listItemProps={{
              onClick: () => onSelectBoard(item.id, item.name),
              button: true,
            }}
            listItemTextProps={{
              primary: `${item.name}`,
            }}
          />
        ))}
        <NavbarItem url="/admin/post/category" title="게시판 카테고리" />
      </Collapse>

      <Divider />
    </>
  );
};

export default Navbar;

import { createReducer } from 'typesafe-actions';
import { CartState } from './interface';
import { CartAction } from './types';
import {
  GET_CART,
  ADD_CHECK_ITEM,
  REMOVE_CHECK_ITEM,
  ADD_ITEM_OPTION,
  REMOVE_ITEM_OPTION,
  REMOVE_CHECKLIST,
} from './actions';
import { Cart } from '../../components/mypage/interface';
import { Option } from '../../components/course/interface';

const initialState: CartState = {
  cartList: [],
  checkList: [],
};

const cart = createReducer<CartState, CartAction>(initialState, {
  [GET_CART]: (state, { payload: list }) => {
    return {
      cartList: list,
      checkList: [...list.map((item) => item.id)],
    };
  },
  [ADD_CHECK_ITEM]: (state, { payload: cartItemId }) => {
    if (state.checkList.includes(cartItemId)) {
      return state;
    }
    return {
      ...state,
      checkList: state.checkList.concat(cartItemId),
    };
  },
  [REMOVE_CHECK_ITEM]: (state, { payload: cartItemId }) => {
    return {
      ...state,
      checkList: state.checkList.filter((id: number) => id !== cartItemId),
    };
  },
  [ADD_ITEM_OPTION]: (state, { payload }) => {
    return {
      ...state,
      cartList: state.cartList.map((item: Cart) => {
        if (item.id === payload.id) {
          return {
            ...item,
            price: item.price + payload.option.price,
            options: item.options.concat(payload.option),
          };
        }
        return item;
      }),
    };
  },
  [REMOVE_ITEM_OPTION]: (state, { payload }) => {
    return {
      ...state,
      cartList: state.cartList.map((item: Cart) => {
        if (item.id === payload.id) {
          return {
            ...item,
            price: item.price - payload.option.price,
            options: item.options.filter(
              (option: Option) => option.id !== payload.option.id,
            ),
          };
        }
        return item;
      }),
    };
  },
  [REMOVE_CHECKLIST]: (state) => {
    return {
      ...state,
      cartList: state.cartList.filter(
        (item: Cart) => !state.checkList.includes(item.id),
      ),
      checkList: [],
    };
  },
});

export default cart;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//컴포넌트
import TermModal from '../term/TermModal'; //이용약관 modal
import SiteMap from './SiteMap'; //사이트맵

//style
import { Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//로고, sns아이콘
import DokhakRogo from '../../../img/logo/logo.png';
import icon_youtube from '../../../img/snsicon/youtube.png';
import icon_naverblog from '../../../img/snsicon/naverblog.png';
import icon_instagram from '../../../img/snsicon/instagram.png';
import icon_navertv from '../../../img/snsicon/navertv.png';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_HELPNUMBER = gql`
  query getSiteConfig {
    SiteConfig {
      helpNumber
    }
  }
`;

const Footer = () => {
  const classes = useStylesFooter();
  const [termOpen1, setTermOpen1] = useState(false);
  const [termOpen2, setTermOpen2] = useState(false);

  const { data } = useQuery(GET_HELPNUMBER, {
    onError: () => console.log('불러오기 실패'),
  });

  //이용약관
  const handleTermOpen1 = () => {
    setTermOpen1(!termOpen1);
  };

  //개인정보처리방침
  const handleTermOpen2 = () => {
    setTermOpen2(!termOpen2);
  };

  //팝업이나 새탭으로 링크 열기
  const handlelink = (link: string, popUp: string | null = null) => {
    if (popUp) window.open(link, popUp, `width=550,height=700,scrollbars=1`);
    else window.open(link);
  };

  const callbox = (
    <div className={classes.callbox}>
      <Typography className={classes.callcenter}>고객센터</Typography>
      <Typography className={classes.callNumber}>
        {data && data.SiteConfig.helpNumber}
      </Typography>
      <Typography className={classes.time}>
        평일 09:00~18:00 (주말/공휴일 휴무)
      </Typography>
    </div>
  );

  //snsicon첫줄
  const snsiconTop = (
    <>
      <img
        src={icon_youtube}
        alt="유튜브"
        onClick={() =>
          handlelink('https://www.youtube.com/channel/UCJYFYyVos-pXaHI7-jC1DTQ')
        }
        className={classes.snsicon}
      />
      <img
        src={icon_naverblog}
        alt="네이버블로그"
        onClick={() => handlelink('https://blog.naver.com/kizdong')}
        className={classes.snsicon}
      />
    </>
  );

  //snsicon둘째줄
  const snsiconBottom = (
    <>
      <img
        src={icon_instagram}
        alt="인스타그램"
        onClick={() =>
          handlelink('https://www.instagram.com/doteplanner/?hl=ko')
        }
        className={classes.snsicon}
      />
      <img
        src={icon_navertv}
        alt="네이버티비"
        onClick={() => handlelink('https://tv.naver.com/gydong')}
        className={classes.snsicon}
      />
    </>
  );

  return (
    <footer className={classes.footer}>
      <SiteMap />
      <div className={classes.buttonbox}>
        <Grid item container className={classes.textwrap} spacing={1}>
          <Grid item onClick={() => handlelink('http://winninglab.com/')}>
            <Typography className={classes.text}>회사소개</Typography>
          </Grid>
          <Grid
            item
            onClick={() => handlelink('http://winninglab.com/?page_id=1510')}
          >
            <Typography className={classes.text}>제휴문의</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text} onClick={handleTermOpen1}>
              이용약관
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text} onClick={handleTermOpen2}>
              개인정보처리방침
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container className={classes.gridcontainer}>
        <Hidden smUp>
          <Grid item xs={12} className={classes.mobilebox} container>
            <Grid item>
              <div className={classes.snsiconbox}>{snsiconTop}</div>
              <div className={classes.snsiconbox}>{snsiconBottom}</div>
            </Grid>
            <Grid item xs>
              {callbox}
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={7} md={8}>
          <Link to="/">
            <img src={DokhakRogo} alt="로고" className={classes.logo} />
          </Link>
          <Typography color="textSecondary" className={classes.textbox}>
            상호: (주)스마트동스쿨 | (03909) 서울특별시 마포구 매봉산로 37
            DMC산학협력연구센터 1005호 | 대표: 나준규
            <br />
            사업자등록번호 209-81-50372 | 통신판매업 신고번호 제
            2012-서울마포-0453 호 | 개인정보관리책임자: 나준규
            <br />
            대표전화 070-4705-0232 | 팩스번호 0303-0101-4242 | e-mail:
            help@smartdongs.com
            <br />
            <br />
            Copyright ⓒ (주)스마트동스쿨 Co.,Ltd. All Rights Reserved.
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={5} md={4}>
            {callbox}
            <div className={classes.snsiconbox}>
              {snsiconTop}
              {snsiconBottom}
            </div>
          </Grid>
        </Hidden>
      </Grid>
      <TermModal open={termOpen1} handleOpen={handleTermOpen1} type={1} />
      <TermModal open={termOpen2} handleOpen={handleTermOpen2} type={2} />
    </footer>
  );
};

export default Footer;

//푸터
const useStylesFooter = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: '100%',
      marginTop: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 67,
      },
      borderTop: '1px solid #E6E6E6',
    },
    gridcontainer: {
      [theme.breakpoints.up('lg')]: {
        width: 1280,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
      padding: theme.spacing(2),
    },
    textbox: {
      fontSize: 15,
      opacity: 0.7,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      fontWeight: 'lighter',
      marginTop: theme.spacing(1),
    },
    buttonbox: {
      borderTop: '1px solid #E6E6E6',
      borderBottom: '1px solid #E6E6E6',
    },
    link: {
      textDecoration: 'none',
    },
    logo: {
      width: 150,
    },
    textwrap: {
      [theme.breakpoints.up('lg')]: {
        width: 1240,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 2),
      },
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        justifyContent: 'space-between',
      },
    },
    text: {
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontWeight: 'lighter',
      },
      fontSize: 18,
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(3),
      },
    },
    callbox: {
      fontSize: 22,
      textAlign: 'right',
    },
    callcenter: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    callNumber: {
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 40,
      },
    },
    mobilebox: {
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(3),
      borderBottom: '1px solid #E6E6E6',
    },
    time: {
      fontWeight: 'lighter',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
    snsiconbox: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
      textAlign: 'right',
      marginTop: theme.spacing(1),
    },
    snsicon: {
      marginRight: 5,
      cursor: 'pointer',
    },
  }),
);

import { createReducer } from 'typesafe-actions';
import { PrintPaperState } from './interface'; //상태ts타입 가져오기
import { PrintPaperAction } from './types'; //액션ts타입 가져오기
import {
  SELECT_PREVIEW,
  SET_VIMEO_PROGRESS,
  SET_PRINT_PAPERS,
  SET_MAX_ORDER,
  ADD_PRINT_PAPERS,
  DELETE_PRINT_PAPERS,
  SET_UPDATE_PRINT_PAPER,
  UPDATE_PRINT_PAPER,
} from './actions'; //액션가져오기

const initialState: PrintPaperState = {
  printPapers: [],
  maxOrder: 0,
  preview: 0,
  progress: {
    all: 0,
    now: 0,
    isRunning: false,
  },
  update: {
    id: 0,
    name: '',
    order: 0,
    folderName: '',
    fileName: '',
    courseId: 0,
  },
};

// 리듀서 만들기
const printPaper = createReducer<PrintPaperState, PrintPaperAction>(
  initialState,
  {
    [SET_PRINT_PAPERS]: (state, { payload }) => {
      return {
        ...state,
        printPapers: payload,
      };
    },
    [SET_MAX_ORDER]: (state, { payload }) => {
      return {
        ...state,
        maxOrder: payload,
      };
    },
    [SELECT_PREVIEW]: (state, { payload: { preview } }) => {
      return {
        ...state,
        preview,
      };
    },
    [SET_VIMEO_PROGRESS]: (state, { payload: { all, now, isRunning } }) => {
      return {
        ...state,
        progress: {
          all,
          now,
          isRunning,
        },
      };
    },
    [ADD_PRINT_PAPERS]: (state, { payload }) => {
      return {
        ...state,
        printPapers: [...state.printPapers, ...payload],
      };
    },
    [DELETE_PRINT_PAPERS]: (state, { payload: { ids } }) => {
      return {
        ...state,
        printPapers: [
          ...state.printPapers.filter((item) => {
            if (!item.id) {
              return false;
            }
            return !ids.includes(item.id);
          }),
        ],
      };
    },
    [SET_UPDATE_PRINT_PAPER]: (state, { payload }) => {
      return {
        ...state,
        update: payload,
      };
    },
    [UPDATE_PRINT_PAPER]: (state, { payload }) => {
      return {
        ...state,
        printPapers: [
          ...state.printPapers.map((item) =>
            item.id === payload.id ? { ...payload } : item,
          ),
        ],
      };
    },
  },
);

export default printPaper;

import React, { useEffect } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useStylesPCTop } from '../../../hooks/header/useStyles';
import { GradientButton, WhiteButton } from '../../common/customStyle/Button';
import { GreyButton } from '../../common/customStyle/Button';
import useLogout from '../../../hooks/common/useLogout';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import useStatus from '../../../hooks/auth/useStatus';
import useAdminAccess from '../../../hooks/auth/useAdminAccess';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_USER_ROLE } from '../gql';

const PCTopRight = (props: RouteComponentProps) => {
  const classes = useStylesPCTop();
  const logout = useLogout(props.history);
  const loginToken = localStorage.getItem('token');
  const {
    isAdmin,
    permission: { pagePermissions },
  } = useStatus();
  const adminAccess = useAdminAccess();

  useEffect(() => {
    if (loginToken === null && isAdmin) logout();
    else if (loginToken) getUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken]);

  const [getUserRole, { data }] = useLazyQuery(GET_USER_ROLE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.user !== undefined) {
        const {
          isAdmin,
          name,
          useSpecificPermission,
          apiPermissions,
          pagePermissions,
        } = data.user.role;
        const accountId = data.user.accountId;

        adminAccess({
          accountId,
          isAdmin,
          name,
          apiPermissions,
          pagePermissions: pagePermissions.map((item: any) => item.url),
          useSpecificPermission,
        });
      }
    },
    onError: (error) => console.log(error),
  });

  return (
    <>
      <Link to="/intro" className={classes.link}>
        <span className={classes.bold}>키즈동소개</span>
      </Link>
      {/* <div className={classes.divider} />
      <Link to="/roadmap" className={classes.link}>
        <span className={classes.bold}>학습로드맵</span>
      </Link> */}
      <div className={classes.divider} />
      <Link to="/review" className={classes.link}>
        <span className={classes.bold}>이용후기</span>
      </Link>
      {/* <div className={classes.divider} />
      <Link to="/free" className={classes.link}>
        <span className={classes.bold}>무료강의</span>
      </Link> */}
      <div className={classes.grow} />
      {loginToken ? (
        <>
          <HeadsetMicOutlinedIcon fontSize="small" />
          <Link to="/help" className={classes.link}>
            고객센터
          </Link>
          <div className={classes.divider} />
          <ShoppingCartOutlinedIcon fontSize="small" />
          <Link to="/mypage/cart" className={classes.link}>
            장바구니
          </Link>
          <div className={classes.divider} />
          <ExitToAppIcon fontSize="small" />
          <span className={classes.link} onClick={logout}>
            로그아웃
          </span>
          {data && data.user.role.isAdmin === true && (
            <Link
              to={pagePermissions[0] || `/admin/order`}
              className={classes.btnlink}
            >
              <GreyButton className={classes.roombtn}>관리자모드</GreyButton>
            </Link>
          )}
          <Link to="/mypage" className={classes.btnlink}>
            <WhiteButton className={classes.roombtn}>내 정보</WhiteButton>
          </Link>
          <Link to="/learning" className={classes.btnlink}>
            <GradientButton className={classes.roombtn}>내 강의</GradientButton>
          </Link>
        </>
      ) : (
        <>
          <PowerSettingsNewOutlinedIcon fontSize="small" />
          <Link to="/login" className={classes.link}>
            로그인
          </Link>
          <div className={classes.divider} />
          <PersonOutlineIcon fontSize="small" />
          <Link to="/register/form" className={classes.link}>
            회원가입
          </Link>
          <div className={classes.divider} />
          <HeadsetMicOutlinedIcon fontSize="small" />
          <Link to="/help" className={classes.link}>
            고객센터
          </Link>
        </>
      )}
    </>
  );
};

export default withRouter(PCTopRight);

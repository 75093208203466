import gql from 'graphql-tag';

export const GET_USER = gql`
  query getCurrentUser {
    user {
      accountType
      accountId
      name
      point
    }
  }
`;

export const GET_USER_ROLE = gql`
  query getUserRole {
    user {
      accountId
      role {
        id
        name
        isAdmin
        useSpecificPermission
        apiPermissions {
          id
          name
          apiType
          allowRead
          allowWrite
          allowDelete
        }
        pagePermissions {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query ProductCategory {
    ProductCategory {
      id
      name
      code
      banner {
        id
        url
      }
      children {
        id
        name
        code
        banner {
          id
          url
        }
      }
    }
  }
`;

export const GET_SEARCH_KEYWORD = gql`
  query searchNowProducts($value: [String!]) {
    products(
      pagination: { take: 8 }
      where: { name: { operator: LIKE, value: $value }, hide: { value: false } }
    ) {
      items {
        id
        name
      }
    }
  }
`;

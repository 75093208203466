//루트 리듀서
import { combineReducers } from 'redux';
import auth from './auth';
import cart from './cart';
import main from './main';
import order from './order';
import adminBoard from './admin/board';
import adminPrintPaper from './admin/printPaper';
import adminProduct from './admin/product';
import adminRandomImage from './admin/randomImage';
import permission from './admin/permission';
import adminTemporary from './admin/temporary';
import adminSetting from './admin/setting';
import adminSite from './admin/site';

const rootReducer = combineReducers({
  auth,
  cart,
  main,
  order,
  permission,
  adminProduct,
  adminRandomImage,
  adminBoard,
  adminPrintPaper,
  adminTemporary,
  adminSetting,
  adminSite,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//헤더 전체
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    mobiledrawerContainer: {
      width: 300,
    },
    toolbar: {
      [theme.breakpoints.up('xl')]: {
        width: 1440,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      position: 'relative',
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    title: {
      width: 150,
      height: 26,
      marginTop: 3,
      marginRight: theme.spacing(3),
    },
    mobiletitle: {
      width: 150,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    grow: {
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
      },
    },
    appbar: {
      background: '#ffffff',
      borderBottom: '1px solid #F0EDED',
    },
    link: {
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        textAlign: 'center',
      },
    },
  }),
);

export default useStyles;

//pc 상단 메뉴
export const useStylesPCTop = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      marginLeft: 2,
      textDecoration: 'none',
      marginRight: theme.spacing(1),
      fontSize: 15,
      color: '#2B2B2B',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    btnlink: {
      textDecoration: 'none',
      marginLeft: theme.spacing(2),
    },
    roombtn: {
      width: 100,
      height: 40,
    },
    divider: {
      height: 10,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      border: '1px solid #707070',
      opacity: 0.2,
    },
    grow: {
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
      },
    },
  }),
);

// pc 카테고리 리스트
export const useStylesPCList = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: 8,
      minWidth: 120,
    },
    arrow: {
      position: 'absolute',
      bottom: -3,
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      maxWidth: 44,
      width: '100%',
      height: 5,
      background: '#FF9633',
    },
    linkbox: {
      width: '100%',
      height: '100%',
      minHeight: 48,
      marginRight: theme.spacing(2),
      position: 'relative',
      paddingTop: 10,
      textAlign: 'center',
      textDecoration: 'none',
      color: '#2B2B2B',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    link: {
      textDecoration: 'none',
      color: '#2B2B2B',
    },
    categoryName: {
      fontWeight: 'bold',
      fontSize: 19,
    },
    divider: {
      borderBottom: '1px solid #E6E6E6',
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 5,
      marginBottom: 5,
    },
    popper: {
      zIndex: 2,
    },
  }),
);

//pc하단 오른쪽 메뉴
export const useStylesPCBottom = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
    icon: {
      paddingTop: 2,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    text: {
      fontSize: 15,
      marginLeft: 22,
    },
    div: {
      width: 130,
      position: 'relative',
      cursor: 'pointer',
    },
  }),
);

//검색(pc,모바일)
export const useStylesSearch = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 500,
      },
      overflow: 'hidden',
    },
    form: {
      padding: theme.spacing(2),
    },
    input: {
      background: 'white',
    },
    searchbtn: {
      marginLeft: theme.spacing(1),
    },
    link: {
      fontSize: 15,
      fontWeight: 'lighter',
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
      },
    },
    morelink: {
      fontSize: 15,
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    searchtitle: {
      textAlign: 'center',
      paddingTop: theme.spacing(2),
      background: '#E6E6E6',
    },
    keywordList: {
      textAlign: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        background: '#F2F2F2',
      },
      borderBottom: '1px solid #DDDDDD',
    },
    result: {
      textAlign: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    searchbox: {
      cursor: 'pointer',
      width: '100%',
      minWidth: 70,
      position: 'relative',
    },
    text: {
      fontSize: 15,
      marginLeft: 21,
    },
    searchicon: {
      position: 'absolute',
      top: 0,
      left: 0,
      paddingTop: 2,
    },
    icon: {
      paddingTop: 10,
    },
  }),
);

//모바일 하단 메뉴
export const useStylesMobileBottom = makeStyles(() =>
  createStyles({
    bottomBar: {
      width: '100%',
      height: 30,
      position: 'relative',
      justifyContent: 'space-around',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
      paddingRight: 5,
      paddingLeft: 5,
    },
    link: {
      textDecoration: 'none',
    },
    text: {
      fontSize: 14,
      cursor: 'pointer',
      color: 'black',
    },
    div: {
      float: 'left',
    },
    divider: {
      height: 8,
      marginTop: 3,
      background: '#707070',
      border: '1px solid #707070',
      opacity: 0.2,
      marginRight: 2,
      marginLeft: 2,
    },
    skeleton: {
      marginRight: 3,
    },
  }),
);

//모바일 오른쪽 햄버거 메뉴
export const useStylesMobileList = makeStyles((theme: Theme) =>
  createStyles({
    logincontainer: {
      padding: theme.spacing(2),
    },
    notlogincontainer: {
      padding: theme.spacing(3),
      paddingRight: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    usericon: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    username: {
      margin: 0,
      fontSize: 21,
      fontWeight: 'bold',
    },
    userid: {
      margin: 0,
      fontSize: 15,
      fontWeight: 'bold',
    },
    logout: {
      marginLeft: 10,
      fontWeight: 'lighter',
      fontSize: 15,
    },
    roombtncontainter: {
      paddingTop: theme.spacing(2),
    },
    roombtn: {
      width: '100%',
      height: 55,
      textAlign: 'left',
    },
    roombtntext: {
      fontSize: 20,
      flexGrow: 1,
      paddingLeft: 10,
    },
    loginlinkbox: {
      paddingBottom: theme.spacing(2),
    },
    registerlinkbox: {
      paddingTop: theme.spacing(3),
    },
    loginlinktext: {
      fontSize: 18,
      color: '#2B2B2B',
      fontWeight: 'bold',
      marginLeft: 10,
    },
    arrowicon: {
      marginTop: 4,
      color: '#2B2B2B',
    },
    notlogindivider: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    bottommenucontainer: {
      marginTop: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
      },
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    bottommenuicon: {
      height: '100%',
      width: 'auto',
      maxHeight: 60,
    },
    bottommenutext: {
      marginTop: 5,
      fontSize: 14,
      color: '#5A5A5A',
      fontWeight: 'bold',
    },
    bottommenufreeicon: {
      height: '100%',
      width: 'auto',
      maxHeight: 60,
      padding: '5px 0',
    },
    categoryname: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#2B2B2B',
      paddingLeft: theme.spacing(1),
    },
    divider: {
      width: '88%',
    },
  }),
);

//모바일 햄버거 메뉴안 카테고리
export const useStylesCategoryItem = makeStyles((theme: Theme) =>
  createStyles({
    categoryname: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#2B2B2B',
      paddingLeft: theme.spacing(1),
    },
    arrowicon: {
      paddingTop: 0,
      fontSize: 12,
      color: '#2B2B2B',
    },
    link: {
      textDecoration: 'none',
    },
    listitem: {
      marginTop: theme.spacing(1),
    },
    collapse: {
      marginBottom: theme.spacing(1),
    },
    childname: {
      paddingLeft: theme.spacing(2),
      fontSize: 15,
      color: '#2B2B2B',
    },
    divider: {
      height: 15,
      width: 3,
      marginTop: 3,
      background: '#FF9E36',
      float: 'left',
      borderRadius: 2,
    },
  }),
);

export interface IListProduct {
  id: number;
  name: string;
  price: number;
  category: {
    id: number;
    name: string;
  };
  contentProvider?: {
    id: number;
    name: string;
  };
  hide: boolean;
}

export interface IDetailProduct {
  id: number;
  name: string;
  price: number;
  realPrice?: number;
  priority: number;
  category: {
    id: number;
    name: string;
  };
  contentProviderId: number | null;
  thumbnail?: {
    id: number;
    url: string;
  };
  landing?: {
    id: number;
    url: string;
  };
  period: number;
  isRecommended: boolean;
  isHit: boolean;
  allCategory?: boolean;
  summary: string;
  interestKeyword: string;
  difficulty: ProductDifficulty;
  target: string;
  method: string;
  characteristic: string;
  isPackage: boolean;
  hide: boolean;
  imageSlides: {
    id: number;
    url: string;
  }[];
  updatedId: number | null;
  synchronizedAt: string | null;
  youtubeId: string | null;
  hiddenPrice: boolean;
}

export interface ICourse {
  id: number;
  name: string;
  price: number;
  period: number;
  folderName: string;
  createdAt: string;
}

export interface IOption {
  id: number;
  title: string;
  price: number;
  image: {
    id: number;
    name: string;
    url: string;
  };
  body: string;
}

export interface IContentProvider {
  id: number;
  name: string;
}

export interface ILecture {
  id: number;
  name: string;
  period: number;
}

export interface ICourseConnect {
  courseItems: Array<number>;
}

export interface FileEx extends File {
  preview: string;
}

export interface IOriginalSlide {
  id: number;
  url: string;
}

export enum PlayColor {
  Grey = 'Grey',
  Blue = 'Blue',
  Green = 'Green',
  Magenta = 'Magenta',
  Orange = 'Orange',
  Yellow = 'Yellow',
}

export interface ThumbStrItem {
  text: string;
  color: string;
}

export interface PackageThumbStr {
  str1: ThumbStrItem;
  str2: ThumbStrItem;
  str3: ThumbStrItem;
  bottom: ThumbStrItem;
}

export type ProductKind = 'ALL' | 'SINGLE' | 'PACKAGE';

export enum ProductType {
  NORMAL = 'NORMAL',
  BOOKREORDER = 'BOOKREORDER',
}

export enum ProductThumbnailType {
  NEW = 'NEW',
  UPLOAD = 'UPLOAD',
  EXISTING = 'EXISTING',
}

export enum ProductDifficulty {
  BEGINNER = '초급',
  INTERMEDIATE = '중급',
  ADVANCED = '고급',
}

import { createAction } from 'typesafe-actions';

import { PrintPaperState, IPrintPaper } from './interface';

export const SET_PRINT_PAPERS = 'printPaper/SET_PRINT_PAPERS';
export const setPrintPapers = createAction(SET_PRINT_PAPERS)<IPrintPaper[]>();

export const SET_MAX_ORDER = 'printPaper/SET_MAX_ORDER';
export const setMaxOrder = createAction(SET_MAX_ORDER)<number>();

export const SELECT_PREVIEW = 'printPaper/SELECT_PREVIEW';
export const selectPreview = createAction(SELECT_PREVIEW)<{
  preview: number;
}>();

export const SET_VIMEO_PROGRESS = 'printPaper/SET_VIMEO_PROGRESS';
export const setVimeoProgress = createAction(SET_VIMEO_PROGRESS)<
  PrintPaperState['progress']
>();

export const ADD_PRINT_PAPERS = 'printPaper/ADD_PRINT_PAPERS';
export const addPrintPapers = createAction(ADD_PRINT_PAPERS)<IPrintPaper[]>();

export const UPDATE_PRINT_PAPER = 'printPaper/UPDATE_PRINT_PAPER';
export const updatePrintPaper = createAction(UPDATE_PRINT_PAPER)<IPrintPaper>();

export const DELETE_PRINT_PAPERS = 'printPaper/DELETE_PRINT_PAPERS';
export const deletePrintPapers = createAction(DELETE_PRINT_PAPERS)<{
  ids: number[];
}>();

export const SET_UPDATE_PRINT_PAPER = 'printPaper/SET_UPDATE_PRINT_PAPER';
export const setUpdatePrintPaper = createAction(
  SET_UPDATE_PRINT_PAPER,
)<IPrintPaper>();

import ThumbPlayGrey from '../../../img/productThumbnail/thumbnail_play_grey.svg';
import ThumbPlayMagenta from '../../../img/productThumbnail/thumbnail_play_magenta.svg';
import ThumbPlayBlue from '../../../img/productThumbnail/thumbnail_play_blue.svg';
import ThumbPlayGreen from '../../../img/productThumbnail/thumbnail_play_green.svg';
import ThumbPlayOrange from '../../../img/productThumbnail/thumbnail_play_orange.svg';
import ThumbPlayYellow from '../../../img/productThumbnail/thumbnail_play_yellow.svg';
import { PlayColor } from '../product/interface';
import { APIType, APIMethodType } from '../permission/interface';
import { ApiPermission } from '../../../modules/auth/interface';
import {
  MultipleImageType,
  FileType,
  SingleImageType,
} from '../setting/images/interface';

export function returnBannerType(
  type: MultipleImageType | SingleImageType,
): FileType {
  switch (type) {
    case 'sliders':
      return FileType['MAIN_SLIDE'];
    case 'ads':
      return FileType['MAIN_AD'];
    case 'bannerPC':
      return FileType['SITE_BANNER_PC'];
    case 'bannerMobile':
      return FileType['SITE_BANNER_MOBILE'];
    case 'intro':
      return FileType['INTRO'];
    case 'introMobile':
      return FileType['INTRO_MOBILE'];
  }
}

export function returnFileType(
  type: FileType,
): MultipleImageType | SingleImageType {
  switch (type) {
    case FileType['MAIN_SLIDE']:
      return 'sliders';
    case FileType['MAIN_AD']:
      return 'ads';
    case FileType['SITE_BANNER_PC']:
      return 'bannerPC';
    case FileType['SITE_BANNER_MOBILE']:
      return 'bannerMobile';
    case FileType['INTRO']:
      return 'intro';
    case FileType['INTRO_MOBILE']:
      return 'introMobile';
  }
}

export function formatDate(
  date: Date | string | number | undefined,
  isSimple?: boolean,
) {
  if (!date) {
    return '날짜가 아님';
  }
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();

  const normalMonth = dateObj.getMonth() + 1;
  const month = normalMonth >= 10 ? normalMonth : `0${normalMonth}`;

  const day =
    dateObj.getDate() >= 10 ? dateObj.getDate() : `0${dateObj.getDate()}`;

  const hour =
    dateObj.getHours() >= 10 ? dateObj.getHours() : `0${dateObj.getHours()}`;

  if (isSimple) {
    return `${year}-${month}-${day}`;
  }

  const minute =
    dateObj.getMinutes() >= 10
      ? dateObj.getMinutes()
      : `0${dateObj.getMinutes()}`;

  const second =
    dateObj.getSeconds() >= 10
      ? dateObj.getSeconds()
      : `0${dateObj.getSeconds()}`;
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function numberComma(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function canvasToBlob(canvas: HTMLCanvasElement): Promise<File> {
  return new Promise((resolve) => {
    canvas.toBlob((blob: any) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      blob.name = Date.now() + '_saved';
      const file = new File([blob], `${Date.now()}_thumb.jpg`, {
        type: 'image/jpeg',
      });
      resolve(file);
    }, 'image/jpeg');
  });
}

interface IProductThumb {
  color: 'Grey' | 'Blue' | 'Green' | 'Magenta' | 'Orange' | 'Yellow';
}

export function returnThumbPlay(color?: PlayColor) {
  const ThumbArr = [
    ThumbPlayGrey,
    ThumbPlayBlue,
    ThumbPlayGreen,
    ThumbPlayMagenta,
    ThumbPlayOrange,
    ThumbPlayYellow,
  ];
  switch (color) {
    case PlayColor.Grey:
      return ThumbArr[0];
    case PlayColor.Blue:
      return ThumbArr[1];
    case PlayColor.Green:
      return ThumbArr[2];
    case PlayColor.Magenta:
      return ThumbArr[3];
    case PlayColor.Orange:
      return ThumbArr[4];
    case PlayColor.Yellow:
      return ThumbArr[5];
    default:
      return ThumbArr[Math.floor(Math.random() * ThumbArr.length)];
  }
}

export function skipString(str: string, skip: number): string {
  return str.length > skip ? `${str.substring(0, skip)}...` : str;
}

export function endDate(startDay: string | Date, period: number): string {
  const endDate = new Date(startDay);

  endDate.setDate(endDate.getDate() + period);

  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(-1);

  const result: string = formatDate(endDate);

  return result;
}

export function betweenDay(
  startDay: string | Date,
  endDay: string | Date,
): number {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const startTime = new Date(startDay).getTime();
  const endTime = new Date(endDay).getTime();

  const result = Math.round((endTime - startTime) / ONE_DAY);

  return result > 0 ? result : 0;
}

export function betweenTime(startDay: string | Date, endDay: string | Date) {
  const startTIme = new Date(startDay).getTime();
  const endTime = new Date(endDay).getTime();

  const milliseconds = endTime - startTIme;

  return new Date(milliseconds).toISOString().slice(11, -5);
}

export function apiTypeTranslation(apiType: APIType) {
  switch (apiType) {
    case APIType.PERMISSION:
      return '등급';
    case APIType.USER:
      return '회원';
    case APIType.ORDER:
      return '주문';
    case APIType.PRODUCT:
      return '과정, 상품';
    case APIType.POINT:
      return '포인트';
    case APIType.IMAGE:
      return '이미지';
    case APIType.SERVICE:
      return '게시판, FAQ';
    case APIType.INQUIRY:
      return '1:1 문의';
    case APIType.CP:
      return 'CP';
    case APIType.MEMO:
      return '회원 메모';
    case APIType.SMS:
      return '문자';
    default:
      return '알 수 없음';
  }
}

export function checkPageAccessPermission(
  page: string,
  pagePermissions: string[],
) {
  const result = pagePermissions.filter((item) => page.includes(item));
  return result.length > 0;
}

export function checkAPIAccessPermission(
  apiType: APIType,
  apiMethodType: APIMethodType,
  apiPermissions: ApiPermission[],
) {
  // 체크 권한값이 allowRead일 때는 다른 권한(allowWrite, allowDelete)가 있어도 통과하도록.
  if (apiMethodType === APIMethodType.allowRead) {
    const result = apiPermissions.findIndex((item) => item.apiType === apiType);
    return result > -1;
  }

  const result = apiPermissions.findIndex(
    (item) => item.apiType === apiType && item[apiMethodType],
  );
  return result > -1;
}

/**
 * 남은 일수의 시작일(오늘)에서 하루를 빼는 이유는
 * 우리 인강의 수강일은 당일도 카운트 하기 때문.
 *
 * 86400000은 하루.
 */
export function getTodayLessonStartDate() {
  return new Date(new Date().getTime() - 86400000);
}

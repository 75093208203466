import { createReducer } from 'typesafe-actions';
import { AuthState } from './interface'; //상태ts타입 가져오기
import { AuthAction } from './types'; //액션ts타입 가져오기
import {
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  FIND_ACCOUNTID,
  LOGOUT,
  ADMIN_ACCESS,
  REGISTER_USERNAME,
  LOGIN_RETURN_URL,
  RESET_PASSWORD,
  GET_USER_INFO,
} from './actions'; //액션가져오기

const initialState: AuthState = {
  login: false,
  logout: false,
  register: false,
  isAdmin: false,
  permission: {
    name: null,
    useSpecificPermission: true,
    apiPermissions: [],
    pagePermissions: [],
  },
  loginReturnUrl: null,
  findAccountId: null,
  resetHash: null,
  expireTime: null,
  name: '',
  accountId: '',
  accountType: '',
  point: 0,
};

// 리듀서 만들기
const auth = createReducer<AuthState, AuthAction>(initialState, {
  [LOGIN_SUCCESS]: (state, { payload: token }) => {
    localStorage.setItem('token', token);

    return {
      ...state,
      login: true,
      logout: false,
    };
  },
  [REGISTER_USERNAME]: (state, { payload: name }) => {
    return {
      ...state,
      name: name,
    };
  },
  [FIND_ACCOUNTID]: (state, { payload: accountId }) => {
    return {
      ...state,
      findAccountId: accountId,
    };
  },
  [RESET_PASSWORD]: (state, { payload }) => {
    return {
      ...state,
      resetHash: payload.resetHash,
      expireTime: payload.expireTime,
    };
  },
  [REGISTER_SUCCESS]: (state, { payload: token }) => {
    localStorage.setItem('token', token);

    return {
      ...state,
      register: true,
      login: true,
      logout: false,
    };
  },
  [LOGOUT]: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('cartIds');
    localStorage.removeItem('loginReturnUrl');

    return {
      ...initialState,
      logout: true,
    };
  },
  [ADMIN_ACCESS]: (
    state,
    {
      payload: {
        accountId,
        isAdmin,
        name,
        useSpecificPermission,
        apiPermissions,
        pagePermissions,
      },
    },
  ) => {
    return {
      ...state,
      isAdmin,
      accountId,
      permission: {
        name,
        useSpecificPermission,
        apiPermissions,
        pagePermissions,
      },
    };
  },
  [LOGIN_RETURN_URL]: (state, { payload: url }) => {
    localStorage.setItem('loginReturnUrl', url);

    return {
      ...state,
      loginReturnUrl: url,
    };
  },
  [GET_USER_INFO]: (state, { payload }) => {
    return {
      ...state,
      name: payload.name,
      accountId: payload.accountId,
      point: payload.point,
      accountType: payload.accountType,
    };
  },
});

export default auth;

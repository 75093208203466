//모바일 햄버거메뉴
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
//hook
import { useStylesMobileList } from '../../../hooks/header/useStyles';
import useStatus from '../../../hooks/auth/useStatus';
import useGetUserInfo from '../../../hooks/mypage/useGetUserInfo';
import useLogout from '../../../hooks/common/useLogout';
import useCategoryStatus from '../../../hooks/index/useStatus';
//gal
import { GET_USER } from '../gql';
import { useLazyQuery } from '@apollo/react-hooks';
//child component
import MobileCategoryListItem from './MobileCategoryListItem';
//material style, icon
import { Divider, Grid, List, ListItem } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//svg icon
import loginicon from '../../../img/mobiledrawer/loginicon.svg';
import registericon from '../../../img/mobiledrawer/registericon.svg';
import helpicon from '../../../img/mobiledrawer/helpicon.svg';
import drawerreviewicon from '../../../img/mobiledrawer/drawerreviewicon.svg';
import mypageicon from '../../../img/mobiledrawer/mypageicon.svg';
import usericon from '../../../img/mobiledrawer/usericon.svg';
import freeprinticon from '../../../img/mobiledrawer/freeprinticon.svg';
import { GradientButton, WhiteButton } from '../../common/customStyle/Button';
import { Category } from '../../course/interface';

const MobileCategoryList = (props: RouteComponentProps) => {
  const classes = useStylesMobileList();
  const login = localStorage.getItem('token');

  const logout = useLogout(props.history); //로그아웃
  const getUserInfo = useGetUserInfo(); //유저정보 가져오기

  const { category } = useCategoryStatus(); // 카테고리
  const { name, accountId, accountType } = useStatus(); //리덕스 유저정보

  //로그인한 상태면 유저정보 가져오기
  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data && data.user) getUserInfo(data.user);
    },
  });

  useEffect(() => {
    if (login) getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  //링크 이동
  const handleLink = (link: string) => {
    props.history.push(link);
  };

  return (
    <>
      {login ? (
        //로그인한 상태
        <Grid container className={classes.logincontainer}>
          <Grid item xs={12} container alignItems="center">
            <Grid item>
              <img
                src={usericon}
                alt="회원_아이콘"
                className={classes.usericon}
              />
            </Grid>
            <Grid item xs>
              <p className={classes.username}>{name ? name : '회원'}님</p>
              <p className={classes.userid}>
                {accountType === 'NORMAL' && accountId}
                {accountType === 'NAVER' && '네이버로그인'}
              </p>
            </Grid>
            <Grid item>
              <WhiteButton onClick={logout} className={classes.logout}>
                로그아웃
              </WhiteButton>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.roombtncontainter}>
            <GradientButton
              className={classes.roombtn}
              onClick={() => handleLink('/learning')}
            >
              <LibraryBooksIcon />
              <span className={classes.roombtntext}>내 강의</span>
              <NavigateNextIcon />
            </GradientButton>
          </Grid>
        </Grid>
      ) : (
        // 로그인안한 상태
        <Grid container className={classes.notlogincontainer}>
          <Grid
            item
            xs={12}
            container
            onClick={() => handleLink('/login')}
            className={classes.loginlinkbox}
          >
            <Grid item>
              <img src={loginicon} alt="로그인_아이콘" />
            </Grid>
            <Grid item xs>
              <span className={classes.loginlinktext}>로그인</span>
            </Grid>
            <Grid item>
              <NavigateNextIcon className={classes.arrowicon} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            onClick={() => handleLink('/register/form')}
            className={classes.registerlinkbox}
          >
            <Grid item>
              <img src={registericon} alt="회원가입_아이콘" />
            </Grid>
            <Grid item xs>
              <span className={classes.loginlinktext}>회원가입</span>
            </Grid>
            <Grid item>
              <NavigateNextIcon className={classes.arrowicon} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* 강의 카테고리 */}
      {/* <List>
        {category.map((item: Category) => (
          <MobileCategoryListItem item={item} key={item.id} />
        ))}
      </List> */}
      <Grid container justify="center">
        <Divider className={classes.divider} />
      </Grid>
      {/* 소개/ 로드맵 */}
      <List>
        <ListItem onClick={() => handleLink('/intro')}>
          <Grid container>
            <Grid item xs>
              <span className={classes.categoryname}>키즈동소개</span>
            </Grid>
            <Grid item className={classes.arrowicon}>
              <NavigateNextIcon />
            </Grid>
          </Grid>
        </ListItem>
        {/* <ListItem onClick={() => handleLink('/roadmap')}>
          <Grid container>
            <Grid item xs>
              <span className={classes.categoryname}>학습로드맵</span>
            </Grid>
            <Grid item className={classes.arrowicon}>
              <NavigateNextIcon />
            </Grid>
          </Grid>
        </ListItem> */}
        <ListItem onClick={() => handleLink('/review')}>
          <Grid container>
            <Grid item xs>
              <span className={classes.categoryname}>이용후기</span>
            </Grid>
            <Grid item className={classes.arrowicon}>
              <NavigateNextIcon />
            </Grid>
          </Grid>
        </ListItem>
        {/* <ListItem onClick={() => handleLink('/free')}>
          <Grid container>
            <Grid item xs>
              <span className={classes.categoryname}>무료강의</span>
            </Grid>
            <Grid item className={classes.arrowicon}>
              <NavigateNextIcon />
            </Grid>
          </Grid>
        </ListItem> */}
      </List>
      {/* 하단 메뉴 */}
      <Grid container className={classes.bottommenucontainer}>
        <Grid item xs={4} onClick={() => handleLink('/mypage')}>
          <img
            src={mypageicon}
            alt="내 정보_아이콘"
            className={classes.bottommenuicon}
          />
          <p className={classes.bottommenutext}>내 정보</p>
        </Grid>
        <Grid item xs={4} onClick={() => handleLink('/help')}>
          <img
            src={helpicon}
            alt="고객센터_아이콘"
            className={classes.bottommenuicon}
          />
          <p className={classes.bottommenutext}>고객센터</p>
        </Grid>
        <Grid item xs={4} onClick={() => handleLink('/review')}>
          <img
            src={drawerreviewicon}
            alt="이용후기_아이콘"
            className={classes.bottommenuicon}
          />
          <p className={classes.bottommenutext}>이용후기</p>
        </Grid>
        {/* <Grid item xs={4} onClick={() => handleLink('/free')}>
          <img
            src={freeprinticon}
            alt="무료강의_아이콘"
            className={classes.bottommenufreeicon}
          />
          <p className={classes.bottommenutext}>무료강의</p>
        </Grid> */}
      </Grid>
    </>
  );
};

export default withRouter(MobileCategoryList);

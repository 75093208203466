import React from 'react';
import { Link } from 'react-router-dom';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
} from '@material-ui/core';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { useSetUrl, useStatus } from '../../../../hooks/admin/site/useSite';

export interface NavbarItemProps extends ListItemTextProps {
  url: string;
  title?: string;
  listItemProps?: React.ComponentProps<typeof ListItem>;
  listItemTextProps?: ListItemTextProps;
}

const NavbarItem = ({
  url,
  title,
  listItemProps,
  listItemTextProps,
}: NavbarItemProps) => {
  const classes = useStylesNavbar();
  const siteInfo = useStatus();
  const setUrl = useSetUrl();

  return (
    <Link
      to={url}
      className={classes.linkText}
      onClick={() => setUrl(url)}
      style={siteInfo.url === url ? { backgroundColor: '#e2e2e2' } : {}}
    >
      <ListItem button className={classes.nested} {...listItemProps}>
        <ListItemIcon>
          <ArrowRightIcon />
        </ListItemIcon>
        <ListItemText primary={title} {...listItemTextProps} />
      </ListItem>
    </Link>
  );
};

export default NavbarItem;

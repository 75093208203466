import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { retryLoad } from './util/loadableUtil';
import HeaderAppBar from './components/header/HeaderAppBar';
import Footer from './components/common/footer/Footer';
import AdminNavbar from './components/admin/AdminTemplate';
import LogCheck from './components/common/api/LogCheck';
import ReactHelmet, {
  ReactHelmetScript,
} from './components/common/ReactHelmet';
// import IndexTemplate from './components/auth/login/IndexTemplate';
const LoginPage = retryLoad(() => import('./pages/user/LoginPage'));
const RegisterPage = retryLoad(() => import('./pages/user/RegisterPage'));
const MyPage = retryLoad(() => import('./pages/user/MyPage'));
const CoursePage = retryLoad(() => import('./pages/user/CoursePage'));
const LearningPage = retryLoad(() => import('./pages/user/LearningPage'));
const OrderPage = retryLoad(() => import('./pages/user/OrderPage'));
const PointPage = retryLoad(() => import('./pages/user/popup/PointPage'));
const HelpPage = retryLoad(() => import('./pages/user/HelpPage'));
const SearchPage = retryLoad(() => import('./pages/user/SearchPage'));
const ReviewPage = retryLoad(() => import('./pages/user/ReviewPage'));
const ReviewFormPage = retryLoad(
  () => import('./pages/user/popup/ReviewFormPage'),
);
const IDPWSearchPage = retryLoad(() => import('./pages/user/IDPWSearchPage'));
const SurveyPage = retryLoad(() => import('./pages/user/popup/SurveyPage'));
const AdminProductPriorityUpdatePage = retryLoad(
  () => import('./pages/user/popup/AdminProductPriorityUpdatePage'),
);
const NotFoundPage = retryLoad(() => import('./pages/user/NotFoundPage'));
const ReceiptPage = retryLoad(() => import('./pages/user/popup/ReceiptPage'));
const IntroPage = retryLoad(() => import('./pages/user/IntroPage'));
const RoadMapPage = retryLoad(() => import('./pages/user/RoadMapPage'));
const FreePage = retryLoad(() => import('./pages/user/FreePage'));
//admin pages
// const AdminIndexPage = (retryLoad(() => import('./pages/admin/IndexPage')));
const AdminUserPage = retryLoad(() => import('./pages/admin/UserPage'));
const AdminMemoPage = retryLoad(() => import('./pages/admin/UserMemoPage'));
const AdminManagerPage = retryLoad(() => import('./pages/admin/ManagerPage'));
const AdminPermissionPage = retryLoad(
  () => import('./pages/admin/PermissionPage'),
);
const AdminRolePage = retryLoad(() => import('./pages/admin/RolePage'));
const AdminPointPage = retryLoad(() => import('./pages/admin/PointPage'));
const AdminOptionPage = retryLoad(() => import('./pages/admin/OptionPage'));
const AdminCoursePage = retryLoad(() => import('./pages/admin/CoursePage'));
const AdminTemporaryPage = retryLoad(
  () => import('./pages/admin/TemporaryPage'),
);
const AdminDeliveryPage = retryLoad(() => import('./pages/admin/DeliveryPage'));
const AdminProductPage = retryLoad(() => import('./pages/admin/ProductPage'));
const AdminPeriodPage = retryLoad(() => import('./pages/admin/PeriodPage'));
const AdminLearningCoursePage = retryLoad(
  () => import('./pages/admin/DownloadLogPage'),
);
const AdminOrderPage = retryLoad(() => import('./pages/admin/OrderPage'));
const AdminInquiryPage = retryLoad(() => import('./pages/admin/InquiryPage'));
const AdminPostPage = retryLoad(() => import('./pages/admin/PostPage'));
const AdminFAQPage = retryLoad(() => import('./pages/admin/FAQPage'));
const AdminSettingPage = retryLoad(() => import('./pages/admin/SettingPage'));
const AdminReviewPage = retryLoad(() => import('./pages/admin/ReviewPage'));
const AdminAnalysisPage = retryLoad(() => import('./pages/admin/AnalysisPage'));
const AdminCPPage = retryLoad(() => import('./pages/admin/CPPage'));
const AdminCPPayPage = retryLoad(() => import('./pages/admin/CPPayPage'));

const IndexPage = retryLoad(() => import('./components/index/IndexBox'));

const App = () => {
  return (
    <>
      <ReactHelmetScript />
      <LogCheck />
      <Switch>
        {/* 팝업페이지 */}
        <Route component={AdminCPPayPage} path="/admin/cppay" />
        <Route
          component={AdminProductPriorityUpdatePage}
          exact
          path="/admin/adminProductUpdate"
        />
        <Route
          path="/admin"
          render={() => (
            <AdminNavbar>
              <ReactHelmet title="관리자모드" />
              <Switch>
                <Route
                  component={() => <Redirect to="/admin/user" />}
                  path="/admin"
                  exact
                />
                {/* <Route component={AdminIndexPage} path="/admin/index" /> */}

                {/* 부관리자도 볼 수 있는 페이지 */}
                <Route component={AdminUserPage} path={'/admin/user'} />

                <Route component={AdminOrderPage} path="/admin/order" />
                <Route component={AdminDeliveryPage} path="/admin/delivery" />

                <Route component={AdminPeriodPage} path="/admin/period" />

                <Route component={AdminOptionPage} path="/admin/option" />
                <Route component={AdminCoursePage} path="/admin/course" />
                <Route component={AdminProductPage} path="/admin/product" />

                {/* 최상위 관리자만 볼 수 있는 페이지 */}
                <Route component={AdminMemoPage} path="/admin/memo" />
                <Route component={AdminManagerPage} path="/admin/manager" />
                <Route
                  component={AdminPermissionPage}
                  path="/admin/permission"
                />
                <Route component={AdminRolePage} path="/admin/role" />
                <Route component={AdminPointPage} path="/admin/point" />
                <Route
                  component={AdminLearningCoursePage}
                  path="/admin/downloadLogs"
                />
                <Route component={AdminTemporaryPage} path="/admin/temporary" />
                <Route component={AdminReviewPage} path="/admin/review" />
                <Route component={AdminFAQPage} path="/admin/faq" />
                <Route component={AdminInquiryPage} path="/admin/inquiry" />
                <Route component={AdminPostPage} path="/admin/post" />
                <Route component={AdminSettingPage} path="/admin/setting" />
                <Route component={AdminAnalysisPage} path="/admin/analysis" />
                <Route component={AdminCPPage} path="/admin/cplist" />
              </Switch>
            </AdminNavbar>
          )}
        />
        {/* 이용자모드 팝업페이지 */}
        <Route component={ReviewFormPage} exact path="/reviewform" />
        <Route component={PointPage} exact path="/mypage/point" />
        <Route component={ReceiptPage} exact path="/receipt" />
        <Route component={SurveyPage} exact path="/survey/:surveyName" />
        {/* 공통헤더가있는 이용자모드 페이지 */}
        <Route
          render={() => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                overflowX: 'hidden',
              }}
            >
              <div
                style={{
                  minHeight: '100vh',
                }}
              >
                <HeaderAppBar />
                <ReactHelmet title="" />
                <Switch>
                  <Route component={IndexPage} exact path="/" />
                  <Route component={LoginPage} exact path="/login" />
                  <Route component={RegisterPage} path="/register" />
                  <Route component={IDPWSearchPage} path="/idpwsearch" />
                  <Route component={MyPage} path="/mypage" />
                  <Route component={CoursePage} path="/course" />
                  <Route component={OrderPage} path="/order" />
                  <Route component={HelpPage} path="/help" />
                  <Route component={SearchPage} exact path="/search" />
                  <Route component={ReviewPage} exact path="/review" />
                  <Route component={LearningPage} path="/learning" />
                  <Route component={IntroPage} exact path="/intro" />
                  <Route component={RoadMapPage} exact path="/roadmap" />
                  <Route component={FreePage} exact path="/free" />
                  <Route component={NotFoundPage} />
                </Switch>
              </div>
              <Footer />
            </div>
          )}
        />
      </Switch>
    </>
  );
};

export default App;

import { createReducer } from 'typesafe-actions';
import { SiteState } from './interface';
import { SiteAction } from './types';

import { CONNECT_URL } from './actions';

const initialState: SiteState = {
  url: '',
};

const reducer = createReducer<SiteState, SiteAction>(initialState, {
  [CONNECT_URL]: (state, { payload }) => {
    return {
      ...state,
      url: payload,
    };
  },
});

export default reducer;

import { createAction } from 'typesafe-actions';
import { MainDataState, MainImageState, RandomImage } from './interface';
import { ProductCategory } from '../../components/index/interface';
import { Board } from '../../components/help/interface';

export const GET_MAIN_DATA = 'main/GET_MAIN_DATA';

export const GET_MAIN_IMAGES = 'main/GET_MAIN_IMAGES';

export const GET_PRODUCT_CATEGORY = 'main/GET_PRODUCT_CATEGORY';

export const GET_BOARD_CATEGORY = 'main/GET_BOARD_CATEGORY';

export const GET_RANDOM_IMAGES = 'main/GET_RANDOM_IMAGES';

//메인페이지 데이터 가져오기
export const getMainData = createAction(GET_MAIN_DATA)<MainDataState>();

//메인페이지 이미지 가져오기
export const getMainImages = createAction(GET_MAIN_IMAGES)<MainImageState>();

//강의카테고리 가져오기
export const getProductCategory = createAction(GET_PRODUCT_CATEGORY)<
  ProductCategory[]
>();

//게시판카테고리 가져오기
export const getBoard = createAction(GET_BOARD_CATEGORY)<Board[]>();

//랜덤이미지 가져오기
export const getRandomImages = createAction(GET_RANDOM_IMAGES)<RandomImage[]>();

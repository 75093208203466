import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import NavbarItem from '../../common/navbar/navbarItem';

const ReviewNavbar = () => {
  const [open, setOpen] = useState(false);

  const onReviewClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={onReviewClick}>
        <ListItemIcon>
          <ChatOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="후기 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavbarItem url="/admin/review/print" title="일반 후기" />
          <NavbarItem url="/admin/review/course" title="포토 후기" />
          <NavbarItem url="/admin/review/bad" title="악성 후기 관리" />
          <NavbarItem url="/admin/review/keyword" title="악성 키워드 관리" />
          <NavbarItem url="/admin/review/blog" title="블로그 리뷰 관리" />
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default ReviewNavbar;

import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Payment from '@material-ui/icons/Payment';
import { useQuery } from 'react-apollo';
import LATEST_ORDER_TIME from '../gql';
import NavbarItem from '../../common/navbar/navbarItem';

const OrderNavbar = () => {
  const [open, setOpen] = useState(false);
  const [LatestOrderDate, setLastOrderDate] = useState(-1);

  const handleContentClick = () => {
    setOpen(!open);
  };

  useQuery(LATEST_ORDER_TIME, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      const parseDate = (parsedDate = '') => {
        if (parsedDate.length === 0) {
          return Math.floor(new Date().getTime() / 1000 / 60 / 60 / 24 + 0.375);
        }

        return Math.floor(
          new Date(parsedDate).getTime() / 1000 / 60 / 60 / 24 + 0.375,
        );
      };

      if (result.orders.items.length === 0) {
        setLastOrderDate(-1);
        return;
      }
      const passedDate =
        parseDate() - parseDate(result.orders.items[0]['createdAt']);

      setLastOrderDate(passedDate);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <Payment />
        </ListItemIcon>
        <ListItemText
          primary="주문 관리"
          secondary={
            <span style={{ color: '#FF9E36', fontWeight: 'bold' }}>
              {LatestOrderDate >= 0
                ? `최근 주문: ${
                    LatestOrderDate ? LatestOrderDate + '일 전' : '오늘'
                  }`
                : ''}
            </span>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavbarItem
            url="/admin/order"
            listItemTextProps={{
              primary: '주문 목록',
              secondary: (
                <span style={{ color: '#FF9E36', fontWeight: 'bold' }}>
                  {LatestOrderDate >= 0
                    ? `최근 주문: ${
                        LatestOrderDate ? LatestOrderDate + '일 전' : '오늘'
                      }`
                    : ''}
                </span>
              ),
            }}
          />
          <NavbarItem url="/admin/delivery" title="배송 관리" />
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default OrderNavbar;

import React, { useState } from 'react';

import {
  ListItem,
  ListItemIcon,
  Collapse,
  List,
  ListItemText,
  Divider,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NavbarItem from '../../common/navbar/navbarItem';
import { OnlyRootAdminComponent } from '../../common/AdminRestrictComponent';

const PeriodNavbar = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText primary="수강 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <NavbarItem url="/admin/period" title="수강 기간관리" />
          <NavbarItem url="/admin/downloadLogs" title="다운로드 로그" />
          <OnlyRootAdminComponent
            component={
              <NavbarItem url="/admin/temporary" title="임의수강신청" />
            }
          />
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default PeriodNavbar;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { connectUrl } from '../../../modules/admin/site';

export function useStatus() {
  const site = useSelector((state: RootState) => state.adminSite);
  return site;
}

export function useSetUrl() {
  const dispatch = useDispatch();
  return useCallback((url: string) => dispatch(connectUrl(url)), [dispatch]);
}

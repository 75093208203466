import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import NavbarItem from '../../common/navbar/navbarItem';

const PointNavbar = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <PlusOneIcon />
        </ListItemIcon>
        <ListItemText primary="포인트 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavbarItem url="/admin/point" title="포인트 내역" />
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default PointNavbar;

//가장 나중에 거가 적용됨
import React from 'react';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  keywords?: string;
  description?: string;
  title: string;
  favicon?: string;
}

const ReactHelmet = ({
  title,
  keywords = '',
  description = '',
  favicon = '',
}: HelmetProps) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:title" content={title} />
      {favicon && <meta property="og:image" content={favicon} />}
      <meta property="og:site_name" content="키즈동스쿨" />
      {description && <meta property="og:description" content={description} />}

      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {favicon && <meta name="twitter:image" content={favicon} />}
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

export default ReactHelmet;

export const ReactHelmetScript = () => {
  return (
    <>
      {window.location.hostname !== 'localhost' && (
        <Helmet>
          <script src="script/facebook.js" type="text/javascript"></script>
        </Helmet>
      )}
    </>
  );
};

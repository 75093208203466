import { createReducer } from 'typesafe-actions';
import {
  ADD_RANDOM_IMAGE,
  REMOVE_RANDOM_IMAGE,
  SET_RANDOM_IMAGE,
} from './actions';
import { RandomImageState } from './interface';
import { RandomImageAction } from './types';

const initialState: RandomImageState = {
  images: [],
};

const reducer = createReducer<RandomImageState, RandomImageAction>(
  initialState,
  {
    [SET_RANDOM_IMAGE]: (state, { payload: { images } }) => {
      return {
        ...state,
        images,
      };
    },
    [ADD_RANDOM_IMAGE]: (state, { payload: { images } }) => {
      return {
        ...state,
        images: [...state.images, ...images],
      };
    },
    [REMOVE_RANDOM_IMAGE]: (state, { payload: { ids } }) => {
      return {
        ...state,
        images: [...state.images.filter((item) => !ids.includes(item.id))],
      };
    },
  },
);

export default reducer;

import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import NavbarItem from '../../common/navbar/navbarItem';

export const SettingNavbar = () => {
  const [open, setOpen] = useState(false);

  const handleContentClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <SettingsApplications />
        </ListItemIcon>
        <ListItemText primary="환경설정" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavbarItem url="/admin/setting/image" title="이미지 관리" />
          <NavbarItem url="/admin/setting/smsForm" title="문자 양식 관리" />
          <NavbarItem url="/admin/setting" title="사이트 정보 관리" />
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default SettingNavbar;

import { createAction } from 'typesafe-actions';

import { IOption } from '../../../components/admin/product/interface';
import { ICourse } from '../../../components/admin/product/interface';

export const SET_CONNECT_COURSE = 'product/SET_CONNECT_COURSE';
export const setConnectCourse = createAction(SET_CONNECT_COURSE)<{
  courses: ICourse[];
}>();

export const CONNECT_COURSE = 'product/CONNECT_COURSE';
export const connectCourse = createAction(CONNECT_COURSE)<{
  courses: ICourse[];
}>();

export const DISCONNECT_COURSE = 'product/DISCONNECT_COURSE';
export const disconnectCourse = createAction(DISCONNECT_COURSE)<{
  ids: number[];
}>();

export const SET_CONNECT_OPTION = 'product/SET_CONNECT_OPTION';
export const setConnectOption = createAction(SET_CONNECT_OPTION)<{
  options: IOption[];
}>();

export const CONNECT_OPTION = 'product/CONNECT_OPTION';
export const connectOption = createAction(CONNECT_OPTION)<{
  options: IOption[];
}>();

export const DISCONNECT_OPTION = 'product/DISCONNECT_OPTION';
export const disconnectOption = createAction(DISCONNECT_OPTION)<{
  ids: number[];
}>();

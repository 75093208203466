// 패밀리사이트 링크모음
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Hidden } from '@material-ui/core';
import DvrIcon from '@material-ui/icons/Dvr';
import sample from '../../../img/backgroundimg/familysiteimg.png';
import samplemobile from '../../../img/backgroundimg/familysiteimgmobile.png';
import FamilySiteListItem from './FamilySiteListItem';
import { familySite, applist, etclist } from './SiteLink';

const FamilySiteList = () => {
  const classes = useStylesFamilySite();

  return (
    <Grid container className={classes.paper}>
      <Hidden smDown>
        <Grid
          item
          className={classes.leftSide}
          style={{ backgroundImage: `url(${sample})` }}
        >
          <div className={classes.overlay} />
          <div className={classes.lefticonbox}>
            <DvrIcon className={classes.lefticon} />
            <Typography className={classes.lefttext}>패밀리 사이트</Typography>
            <Typography className={classes.lefttext}>바로가기</Typography>
          </div>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          item
          xs={12}
          className={classes.leftSide}
          style={{ backgroundImage: `url(${samplemobile})` }}
        >
          <div className={classes.overlay} />
          <div className={classes.lefticonbox}>
            <Typography className={classes.lefttext}>
              <DvrIcon /> 패밀리 사이트 바로가기
            </Typography>
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={12} md>
        <Grid container spacing={2} className={classes.sitecontainer}>
          <FamilySiteListItem siteList={familySite} text="인터넷 강의" />
          <FamilySiteListItem siteList={applist} text="플래너 및 타이머" />
          <FamilySiteListItem siteList={etclist} text="기타" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FamilySiteList;

export const useStylesFamilySite = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        width: 1100,
        height: 830,
      },
    },
    leftSide: {
      width: 200,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 92,
        marginBottom: theme.spacing(2),
      },
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.7)',
    },
    lefticonbox: {
      top: '30%',
      position: 'relative',
      textAlign: 'center',
    },
    lefticon: {
      color: '#ffffff',
      fontSize: 120,
    },
    lefttext: {
      color: '#ffffff',
      fontSize: 25,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    titlebox: {
      marginTop: theme.spacing(1),
      color: '#2B2B2B',
    },
    sitecontainer: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 4, 4),
      },
    },
    linkbox: {
      border: '1px solid #E6E6E6',
      padding: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
        backgroundColor: '#F2F2F2',
        transition: 'background-color 0.2s',
      },
    },
    sitename: {
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
      fontSize: 15,
    },
    subtext: {
      [theme.breakpoints.up('md')]: {
        fontSize: 15,
      },
      fontSize: 13,
      fontWeight: 'lighter',
    },
    arrowicon: {
      fontSize: 15,
      color: '#707070',
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    titledivider: {
      marginTop: 5,
      marginRight: theme.spacing(1),
      background: '#FF9E36',
      border: '2px solid #FF9E36',
      height: 20,
      float: 'left',
      borderRadius: 2,
    },
  }),
);
